$(document).on('turbolinks:load', function() {
    $('.admin-login-name-field').on('input',function(){
        let text = $(this).val();
        if(text == 'max'){
          $('.login-button').html("<button class='admin-login-button-font'>ログイン</button>")
        }else {
          $('.login-button').html("")
        }
    });
});


  