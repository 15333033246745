$(document).on('turbolinks:load', function() {
    const ham = $('.fa-bars');
    const nav = $('.header-right-900');
    const cross = $('.fa-xmark')
    ham.on('click', function () {
      nav.toggleClass('active'); 
      $('body').css('overflow-y', 'hidden');
      $('.header-bg').css('display','block');
    });
    cross.on('click', function () {
      nav.toggleClass('active'); 
      $('body').css('overflow-y', 'auto');
      $('.header-bg').css('display','none');
    });
    $('.header-bg').on('click',function(){
      nav.toggleClass('active'); 
      $('body').css('overflow-y', 'auto');
      $('.header-bg').css('display','none');
    });
    $('.header-right-link-900').on('click',function(){
      $('.header-right-900').css('display','none');
    });
});


